import React, { useState } from 'react';
import './GehaelterEntdecken.scss';

const GehaelterEntdecken = ({ darkMode }) => {
  const [jobTitle, setJobTitle] = useState('');
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [region, setRegion] = useState('Deutschland');
  const [viewType, setViewType] = useState('Grafik');
  const [selectedJob, setSelectedJob] = useState(null);

  const jobSuggestions = [
    'Medizinisch-technischer Laboratoriumsassistent',
    'Softwareentwickler',
    'Pflegekraft',
    'Datenanalyst',
    'Arzt',
    'Bauleiter',
  ];

  const exampleSalaryData = {
    quartiles: [3169, 3821, 4579],
    median: 3821,
    gender: { male: 3870, female: 3812 },
    ageGroups: { '<25': 3500, '25-54': 4000, '55+': 3700 },
    relatedJobs: [
      'Medizin Laborant/in',
      'Medizinisch-technischer Laboratoriumsassistent/in',
      'Zytologieassistent/in',
      'Medizinischer Technologe/in',
    ],
  };

  const handleInputChange = (e) => {
    const input = e.target.value;
    setJobTitle(input);
    if (input.length > 0) {
      const filtered = jobSuggestions.filter((job) =>
        job.toLowerCase().includes(input.toLowerCase())
      );
      setFilteredSuggestions(filtered);
    } else {
      setFilteredSuggestions([]);
    }
  };

  const selectSuggestion = (suggestion) => {
    setJobTitle(suggestion);
    setFilteredSuggestions([]);
    setSelectedJob(exampleSalaryData);
  };

  const handleRegionChange = (e) => {
    setRegion(e.target.value);
  };

  const handleViewTypeChange = (type) => {
    setViewType(type);
  };

  return (
    <div className={`gehaelter-entdecken ${darkMode ? 'dark-mode' : 'light-mode'}`}>
      <div className="search-section">
        <input
          type="text"
          value={jobTitle}
          onChange={handleInputChange}
          placeholder="Suchbegriff eingeben"
          className="search-input"
        />
        {filteredSuggestions.length > 0 && (
          <ul className="suggestions-list">
            {filteredSuggestions.map((suggestion, index) => (
              <li key={index} onClick={() => selectSuggestion(suggestion)}>
                {suggestion}
              </li>
            ))}
          </ul>
        )}

        <select value={region} onChange={handleRegionChange} className="region-dropdown">
          <option value="Deutschland">Deutschland</option>
          <option value="Bayern">Bayern</option>
          <option value="NRW">Nordrhein-Westfalen</option>
        </select>

        <div className="view-type">
          <button
            className={`view-button ${viewType === 'Grafik' ? 'active' : ''}`}
            onClick={() => handleViewTypeChange('Grafik')}
          >
            Grafik
          </button>
          <button
            className={`view-button ${viewType === 'Tabelle' ? 'active' : ''}`}
            onClick={() => handleViewTypeChange('Tabelle')}
          >
            Tabelle
          </button>
        </div>
      </div>

      {selectedJob && viewType === 'Grafik' && (
        <div className="chart-container">
          <h2>Entgelt für den Beruf: {jobTitle}</h2>
          <p>Anforderungsniveau: <span className="highlight">Fachkraft</span></p>

          <div className="chart-bar-container">
            {selectedJob.quartiles.map((value, index) => (
              <div className="chart-bar" key={index}>
                <div className="bar" style={{ height: `${(value / 5000) * 100}%` }}>
                  <span className="bar-value">{value.toLocaleString('de-DE')} €</span>
                </div>
                <span className="bar-label">{['25%', '50%', '75%'][index]}</span>
              </div>
            ))}
          </div>

          <p className="chart-note">
            Das Medianentgelt für den Beruf "{jobTitle}" in der Berufsgattung "Medizinisch-technische Berufe"
            beträgt {selectedJob.median.toLocaleString('de-DE')} €.
          </p>
        </div>
      )}

      {selectedJob && viewType === 'Tabelle' && (
        <div className="table-container">
          <h2>Gehaltsübersicht für {jobTitle}</h2>
          <table>
            <thead>
              <tr>
                <th>Quartil</th>
                <th>Gehalt (€)</th>
              </tr>
            </thead>
            <tbody>
              {selectedJob.quartiles.map((value, index) => (
                <tr key={index}>
                  <td>{['25%', '50% (Median)', '75%'][index]}</td>
                  <td>{value.toLocaleString('de-DE')} €</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default GehaelterEntdecken;
