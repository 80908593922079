import React, { useState } from 'react';
import './FeedbackForm.scss';

const FeedbackForm = ({ onClose }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await fetch('http://localhost:4000/api/send-feedback', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, message }),
    });

    if (response.ok) {
      alert('Feedback erfolgreich gesendet!');
      onClose();
    } else {
      alert('Fehler beim Senden des Feedbacks.');
    }
  };

return (
    <div className="feedback-form">
      <h2>Feedback</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          placeholder="Dein Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          required
        />
        <input
          type="email"
          placeholder="Deine E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <textarea
          placeholder="Dein Feedback"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          required
        />
        <button type="submit">Senden</button>
        <button type="button" onClick={onClose}>Schließen</button>
      </form>
    </div>
  );
};

export default FeedbackForm;