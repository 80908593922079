import React, { useState } from 'react';
import './karrieretipps.scss';

const Karrieretipps = () => {
  const [selectedTip, setSelectedTip] = useState(null);

  const tips = [
    {
      id: 1,
      title: 'Erfolgreich Netzwerken',
      image: <img src={require('./img/networking.webp')} alt="Ein Brettspiel wird als Networking dargestellt" width="150" height="150"/>,
      content: `
        Netzwerken ist eine essenzielle Fähigkeit, um beruflich erfolgreich zu sein. 
        Es geht nicht nur darum, Kontakte zu knüpfen, sondern auch darum, bestehende Beziehungen zu pflegen. 
        Besuche Branchenveranstaltungen, Messen oder Webinare, um Menschen aus deiner Branche kennenzulernen. 
        Plattformen wie LinkedIn sind ebenfalls großartige Werkzeuge, um deine beruflichen Netzwerke zu erweitern. 
        Denk daran: Ein starkes Netzwerk kann dir bei der Jobsuche, Karriereentwicklung und sogar beim Wissenserwerb helfen.
      `,
    },
    {
      id: 2,
      title: 'Zeitmanagement verbessern',
      image: <img src={require('./img/timemanagement.jpeg')} alt="Eine Sanduhr läuft runter" width="150" height="150"/>,
      content: `
        Effektives Zeitmanagement ist entscheidend, um produktiv zu sein. 
        Beginne damit, klare Ziele für den Tag, die Woche oder den Monat zu setzen. 
        Tools wie To-Do-Listen oder Kalender-Apps können dir helfen, den Überblick zu behalten. 
        Plane Zeitblöcke für konzentriertes Arbeiten ein und eliminiere Ablenkungen wie Social Media während dieser Phasen. 
        Vergiss nicht, Pausen einzuplanen – sie fördern deine Konzentration und Kreativität.
      `,
    },
    {
      id: 3,
      title: 'Soft Skills entwickeln',
      image: <img src={require('./img/softskills.webp')} alt="Ein Mann spricht zu einer Gruppe" width="230" height="150"/>,
      content: `
        Soft Skills wie Kommunikation, Teamarbeit und Problemlösung sind genauso wichtig wie Fachwissen. 
        Sie helfen dir, besser mit Kollegen, Kunden und Vorgesetzten zu interagieren. 
        Übe aktives Zuhören, sei offen für Feedback und arbeite daran, Konflikte konstruktiv zu lösen. 
        Workshops oder Online-Kurse können dir dabei helfen, diese Fähigkeiten gezielt zu entwickeln.
      `,
    },
    {
      id: 4,
      title: 'Wie schreibe ich einen perfekten Lebenslauf?',
      image: <img src={require('./img/lebenslauf.jpeg')} alt="Zwei Weiße Druckerpapiere In Der Nähe Von Macbook Auf Brauner Oberfläche" width="230" height="150"/>,
      content: `
        Dein Lebenslauf ist oft das erste, was potenzielle Arbeitgeber von dir sehen. 
        Achte darauf, dass er übersichtlich, prägnant und fehlerfrei ist. 
        Verwende eine klare Struktur mit Abschnitten wie "Persönliche Daten", "Berufserfahrung", "Bildung" und "Fähigkeiten". 
        Hebe relevante Erfahrungen hervor und passe deinen Lebenslauf an die jeweilige Stelle an. 
        Ein professionelles Design kann ebenfalls den Unterschied machen.
      `,
    },
    {
      id: 5,
      title: 'Karrierewechsel erfolgreich meistern',
      image: <img src={require('./img/karrierewechsel.webp')} alt="Nahaufnahme Der Hand Die Text über Schwarzem Hintergrund Hält" width="230" height="150"/>,
      content: `
        Ein Karrierewechsel kann herausfordernd, aber auch äußerst lohnend sein. 
        Analysiere deine Stärken, Interessen und Werte, um den richtigen neuen Weg zu finden. 
        Weiterbildung oder Umschulungen können dir helfen, die nötigen Qualifikationen zu erwerben. 
        Netzwerken und die Nutzung von Karriere-Coaches können den Übergang erleichtern.
      `,
    },
  ];

  const handleTipClick = (tip) => {
    setSelectedTip(tip);
  };

  const closePopup = () => {
    setSelectedTip(null);
  };

  return (
    <div className="karrieretipps">
      <h2>Karrieretipps</h2>
      <div className="tips-grid">
        {tips.map((tip) => (
          <div key={tip.id} className="tip-card" onClick={() => handleTipClick(tip)}>
            <div className="svg-placeholder">{tip.image}</div>
            <h3>{tip.title}</h3>
          </div>
        ))}
      </div>

      {selectedTip && (
        <div className="popup">
          <div className="popup-content">
            <button className="close-button" onClick={closePopup}>X</button>
            <div className="svg-placeholder">{selectedTip.image}</div>
            <h2>{selectedTip.title}</h2>
            <p>{selectedTip.content}</p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Karrieretipps;
