import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink } from 'react-router-dom';
import Karrieretipps from './karrieretipps';
import GehaelterEntdecken from './GehaelterEntdecken';
import UeberUns from './ueberuns';
import Datenschutzerklärung from './Datenschutzerklärung';
import Impressum from './Impressum';
import FeedbackForm from './FeedbackForm';
import ReactMarkdown from 'react-markdown';
import 'ldrs/helix';
import 'ldrs/ring2';
import './App.scss';

const App = () => {
  const [darkMode, setDarkMode] = useState(true);
  const [selectedJob, setSelectedJob] = useState('');
  const [loading, setLoading] = useState(false);
  const [careerInfo, setCareerInfo] = useState('');
  const [showFeedbackForm, setShowFeedbackForm] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  const handleJobChange = (event) => {
    setSelectedJob(event.target.value);
  };

  const handleSubmit = async () => {
    if (selectedJob.trim() === '') return;
    setLoading(true);
    setCareerInfo('');
    await new Promise((resolve) => setTimeout(resolve, 2000));
    const response = await fetchGPTResponse(selectedJob);
    setCareerInfo(response);
    setLoading(false);
  };

  const fetchGPTResponse = async (selectedJob) => {
    const apiKey = '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';
    const url = 'https://api.openai.com/v1/chat/completions';

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${apiKey}`,
      },
      body: JSON.stringify({
        model: 'gpt-3.5-turbo',
        messages: [
          { role: 'system', content: 'Du bist ein Profi in der Karrierenberatung spezialisiert auf Bio und Medizin' },
          { role: 'user', content: `Ich bin zur Zeit ein ${selectedJob}, welche Karrieremöglichkeiten habe?` },
        ],
      }),
    });

    const data = await response.json();
    return data.choices[0].message.content;
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  useEffect(() => {
    const inputElement = document.querySelector('.modern-input');
    if (inputElement) {
      inputElement.addEventListener('keydown', handleKeyPress);
    }

    return () => {
      if (inputElement) {
        inputElement.removeEventListener('keydown', handleKeyPress);
      }
    };
  }, [selectedJob]);

  return (
    <div className={darkMode ? 'app dark-mode' : 'app light-mode'}>
      <Router>
        <header className="apple-header">
          <h1 className="apple-title"></h1>
          <nav className="navigation-bar">
            <NavLink to="/" className="nav-link" activeClassName="active">Karriere entdecken</NavLink>
            <NavLink to="/karrieretipps" className="nav-link" activeClassName="active">Karrieretipps</NavLink>
            <NavLink to="/gehaelter-entdecken" className="nav-link" activeClassName="active">Gehälter Entdecken</NavLink>

          </nav>
        </header>

        <main className="main-content">
          <button onClick={toggleDarkMode} className="toggle-darkmode">
            {darkMode ? '☀️' : '🌙'}
          </button>

          <Routes>
            <Route
              exact
              path="/"
              element={
                <div className="container">
                  <p className="platform-description apple-style">
                    Unsere Plattform bietet Informationen und Karrierechancen für Medizinische Technologen für Laboratoriumsanalytik und Medizinisch-technische Laborassistenten.
                    <br/> <br/>
                    Entdecke verschiedene Ausbildungsmöglichkeiten, Aufstiegschancen und potenzielle Führungspositionen – alles übersichtlich und interaktiv dargestellt. Nutze die KI-basierte Beratung, um deine nächsten Schritte in der beruflichen Laufbahn zu planen.
                  </p>
                  {!loading && (
                    <div className="job-section">
                      <input
                        type="text"
                        placeholder="Gib deine Berufsbeschreibung ein"
                        onChange={handleJobChange}
                        className={`modern-input ${darkMode ? 'dark-mode' : 'light-mode'}`}
                      />
                      <button onClick={handleSubmit} className="submit-button">
                        Fortfahren
                      </button>
                      <p className="explanation-text apple-style-small">
                        1. Beruf angeben <br />
                        2. Karrierechancen entdecken
                      </p>
                    </div>
                  )}

                  {loading && (
                    <div className="loading">
                      <l-ring-2 size="40" stroke="5" stroke-length="0.25" bg-opacity="0.1" speed="0.8" color="green"></l-ring-2>
                      <p>Lädt...</p>
                    </div>
                  )}

                  {careerInfo && (
                    <div className="career-info">
                      <ReactMarkdown>{careerInfo}</ReactMarkdown>
                    </div>
                  )}
                </div>
              }
            />
            <Route path="/datenschutzerklaerung" element={<Datenschutzerklärung />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/karrieretipps" element={<Karrieretipps />} />
            <Route path="/gehaelter-entdecken" element={<GehaelterEntdecken />} />
          </Routes>
        </main>

        <footer className={`footer ${darkMode ? 'dark-mode' : 'light-mode'}`}>
          <p>© 2024 MTL-Karriere.de</p>
          <p>
            <NavLink to="/datenschutzerklaerung">Datenschutzerklärung</NavLink> | <NavLink to="/impressum"> Impressum</NavLink> | <button onClick={() => setShowFeedbackForm(true)}>Feedback</button>
          </p>
        </footer>

        {showFeedbackForm && (
          <>
            <div className="feedback-form-overlay" onClick={() => setShowFeedbackForm(false)}></div>
            <FeedbackForm onClose={() => setShowFeedbackForm(false)} />
          </>
        )}
      </Router>
    </div>
  );
};

export default App;
